import { useEffect, useState } from "react"
import MainPageCatalog from "../Components/MainPageCatalog/MainPageCatalog"
import SendIcon from '@mui/icons-material/Send';
import MainPageFilter from "../Components/MainPageFilter/MainPageFilter"
import Services from "../Components/Services/Services"
import { Button, FormControl, Input, InputAdornment, InputLabel, Autocomplete, Paper, TextField } from "@mui/material"
import PhoneIcon from '@mui/icons-material/PhoneAndroid';
import PersonIcon from '@mui/icons-material/Person';
import axios from "axios";
import { Modal } from "react-bootstrap";
import { LoadingButton } from '@mui/lab';
import ArticleIcon from '@mui/icons-material/Article';

const MainPage = () => {
    const [formData, setFormData] = useState({ phone: "", name: "" });
    const [notification, setNotification] = useState({ show: false, title: "", body: "" });
    const [title, setTitle] = useState("");
    const [calculator, setCalculator] = useState({
        port: {},
        auction: [],
        car_type: [],
        destination: [],
        price: "0.00"
    });
    const [titleLoader, setTitleLoader] = useState(false);
    const [calculating, setCalculating] = useState(false);
    const [progress, setProgress] = useState(0);
    const [calculatorForm, setCalculatorForm] = useState({
        port: "",
        auction: "",
        car_type: "",
        destination: "",
    });
    const menuStyle = {
        textAlign: 'left', // Set text alignment to right
    };
    const [titlesList, setTitlesList] = useState([]);
    const [showTitles, setShowTitles] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get("/api/ports")
            .then((res) => {
                setCalculator({
                    auction: ["IAAI", "COPART"],
                    destination: [...res.data.data.destination_ports],
                    car_type: [...res.data.data.car_types],
                    port: { ...res.data.data.ports },
                    price: "0.00"
                })
            })
            .catch(() => {
                setNotification({ show: true, title: "შეცდომა", body: "კალკულატორი მონაცემები ვერ ჩაიტვირთა" })
            })
    }, [])

    const sendConsultationRequest = () => {
        axios.post('/api/consultation', formData)
            .then(() => {
                setNotification({ show: true, title: "მოთხოვნა წარმატებით გაიგზავნა", body: "კონსულტაციის მოთხოვნა გაიგზავნა. დაელოდეთ ჩვენი წარმომადგენლის ზარს." });
                setFormData({ phone: "", name: "" })
            })
            .catch(() => {
                setNotification({ show: true, title: "შეცდომა", body: "რაღაც გაუთვალისწინებელი მოხდა" })
            })
    }

    const handlers = {
        priceCalculate() {
            setCalculating(true);
            const timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress < 99 ? prevProgress + 1 : 99));
            }, 50);
            axios(`/api/calculate?auction=${calculatorForm.auction}&port=${calculatorForm.port}&car_type=${calculatorForm.car_type}&destination=${calculatorForm.destination}`)
                .then((res) => {
                    const data = res.data.price;
                    setProgress(0);
                    setCalculating(false);
                    setCalculator((prev) => {
                        return { ...prev, price: data }
                    })
                    clearInterval(timer);
                })
                .catch((err) => {
                    setProgress(0);
                    setCalculating(false);
                    setNotification({ show: true, title: "შეცდომა", body: "რაღაც გაუთვალისწინებელი მოხდა" });
                    clearInterval(timer);
                })
        },
        checkTitle() {
            setTitleLoader(true);
            axios.get(`/api/title?title=${title}`)
                .then((res) => {
                    setTitleLoader(false);
                    const data = res.data.title;
                    setTitlesList(data);
                    setShowTitles(true);
                })
                .catch((err) => {
                    setNotification({ show: true, title: "შეცდომა", body: "რაღაც გაუთვალისწინებელი მოხდა" });
                })
        }
    }

    return (
        <>
            <div className={"main-page-banner"} style={{ backgroundImage: `url('/static/icons/banner.jpg')` }}>
                <div className={"main-page-banner-content"}>
                    <h1>ჩამოიყვანე ავტომობილი ამერიკიდან</h1>
                </div>
            </div>
            <MainPageFilter />
            <Services />
            <MainPageCatalog />
            <div className="check-title-container">
                <div className="check-title-form">
                    <h3 style={{ width: "100%", textAlign: "center" }} className="section-title">შეამოწმეთ საბუთის ტიპი</h3>
                    <input type="text" className="form-control" placeholder="ჩაწერეთ დოკუმენტის ტიპი" onChange={(e) => setTitle(e.target.value)} />
                    <LoadingButton
                            loading={titleLoader}
                            loadingPosition="start"
                            startIcon={<ArticleIcon />}
                            variant="outlined"
                            onClick={handlers.checkTitle}
                        >
                            შემოწმება
                        </LoadingButton>
                </div>
            </div>
            <div className="consultation-container">
                <h2>კონსულტაცია გჭირდებათ?</h2>
                <h5>შეავსეთ კონსულტაციის ფორმა და ჩვენი ექსპერტი მალე დაგიკავშირდებათ</h5>
                <form className="consultation-form">
                    <FormControl variant="standard" className="consultation-fields">
                        <InputLabel htmlFor="input-with-icon-adornment">
                            თქვენი სახელი
                        </InputLabel>
                        <Input
                            required
                            type='text'
                            value={formData.name}
                            onChange={(e) => !(/^[0-9]+$/.test(e.target.value)) && setFormData((prev) => ({ ...prev, name: e.target.value }))}
                            name='name'
                            id="name"
                            startAdornment={
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl variant="standard" className="consultation-fields">
                        <InputLabel htmlFor="input-with-icon-adornment">
                            ტელ. ნომერი
                        </InputLabel>
                        <Input
                            required
                            type='text'
                            value={formData.phone}
                            onChange={(e) => (/^[0-9]+$/.test(e.target.value) || e.target.value === "") && setFormData((prev) => ({ ...prev, phone: e.target.value }))}
                            name='phone'
                            id="phone"
                            startAdornment={
                                <InputAdornment position="start">
                                    <PhoneIcon />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <Button endIcon={<SendIcon />} onClick={sendConsultationRequest} variant="contained">გაგზავნა</Button>
                </form>
            </div>
            <Modal show={notification.show} onHide={() => setNotification((prev) => ({ ...prev, show: false }))} size="md">
                <Modal.Header><h4>{notification.title}</h4></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
            </Modal>

            <Modal show={showTitles} onHide={() => setShowTitles(false)} centered size="lg">
                <Modal.Header>დოკუმენტის ტიპი</Modal.Header>
                <Modal.Body>
                    {titlesList.map((t) => {
                        return <p>{t}</p>
                    })}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MainPage