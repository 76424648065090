import { useState } from "react";
import UpIcon from "./UpIcon";
import DownIcon from "./DownIcon";
import styles from "./Questions.module.css";

const Questions = (props) => {
  const [isShowen, setIsShowen] = useState(false);

  const onQuestionClickHandler = () => {
    setIsShowen(!isShowen);
  };

  return (
    <div className={styles["frequency-questions-block"]}>
      <div
        className={styles["question-container"]}
        onClick={onQuestionClickHandler}
      >
        <div className={styles['questions-content']}>
          <h4 className={styles["question"]}>{props.question}</h4>
          {isShowen ? (
            <UpIcon iconWidth="15" color="#797979" />
          ) : (
            <DownIcon iconWidth="15" color="#797979" />
          )}
        </div>
      </div>
      {isShowen && (
        <div className={styles["answer-block"]}>
          <h4 className={styles["answer"]}>{props.answer}</h4>
        </div>
      )}
    </div>
  );
};

export default Questions;
