import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import './MainPageFilter.css'
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    customAutocomplete: {
        '& .MuiAutocomplete-inputRoot': {
            height: '45px', // Change the height to your desired value
        },
    },
}));

function MainPageFilter() {
    const [marks, setMarks] = React.useState([]);
    const [models, setModels] = React.useState([]);
    const [list, setList] = React.useState([]);
    const [formData, setFormData] = React.useState({mark: "", model: "", yearFrom: "", yearTo: ""})
    const classes = useStyles();

    const [years, setYears] = React.useState({
        from: getYearsArray().reverse(),
        to: getYearsArray().reverse()
    });

    React.useEffect(() => {
        axios('/api/cars/models')
            .then((res) => {
                const data = res.data.data;
                setList([...data]);
                const uniqueMarks = new Set();
                const filteredArray = data.filter(item => {
                    if (!uniqueMarks.has(item.mark)) {
                        uniqueMarks.add(item.mark);
                        return true;
                    }
                    return false;
                });
                const mark = filteredArray.map((a) => a.mark)
                setMarks([...mark])
            })
            .catch((err) => {

            })
    }, [])

    const handlers = {
        marksChange(e, newValue){
            const selectModels = list.filter((m) => m.mark === newValue);
            const model = selectModels.map((a) => a.model)
            setModels([...model]);
            setFormData((prev) => ({...prev, mark: newValue}))
        },
        modelsChange(e, newValue){
            setFormData((prev) => ({...prev, model: newValue}))
        },
        yearsFromChange(e, newValue){
            setFormData((prev) => ({...prev, yearFrom: newValue}));
            setYears((prev) => {
                const f = prev.to.filter((y) => y > newValue)
                return {...prev, to: f}
            })
        },
        yearsToChange(e, newValue){
            setFormData((prev) => ({...prev, yearTo: newValue}))
            setYears((prev) => {
                const f = prev.from.filter((y) => y < newValue)
                return {...prev, from: f}
            })
        },
        submitSearch(){
            window.location.href = `/catalogue?mark=${formData.mark}&model=${formData.model}&yearFrom=${formData.yearFrom}&yearTo=${formData.yearTo}`
        }
    }

    return (
        <div className='filter-cars-container'>
            <div className='filter-cars-content'>
                <Autocomplete
                    className={classes.customAutocomplete}
                    disablePortal
                    id="cars-maker-list"
                    options={marks}
                    onChange={handlers.marksChange}
                    sx={{ width: 250, height: 45 }}
                    renderInput={(params) => <TextField {...params} label="მარკა" />}
                />
                <Autocomplete
                    className={classes.customAutocomplete}
                    disablePortal
                    id="cars-model-list"
                    onChange={handlers.modelsChange}
                    options={models}
                    sx={{ width: 250, height: 45 }}
                    renderInput={(params) => <TextField {...params} label="მოდელი" />}
                />
                <Autocomplete
                    className={classes.customAutocomplete}
                    disablePortal
                    id="cars-year-from-list"
                    options={years.from}
                    onChange={handlers.yearsFromChange}
                    sx={{ width: 125, padding: 0 }}
                    renderInput={(params) => <TextField {...params} label="წელი - დან" />}
                />
                <Autocomplete
                    className={classes.customAutocomplete}
                    disablePortal
                    id="cars-year-to-list"
                    onChange={handlers.yearsToChange}
                    options={years.to}
                    sx={{ width: 125, padding: 0 }}
                    renderInput={(params) => <TextField {...params} label="წელი - მდე" />}
                />
                <Button className='search-fiealds' variant="contained" startIcon={<SearchIcon />} onClick={handlers.submitSearch}>
                    ძებნა
                </Button>
            </div>
        </div>
    )
}

function getYearsArray() {
    const currentYear = new Date().getFullYear();
    const startYear = 1970;
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
    }

    return years;
}

export default MainPageFilter