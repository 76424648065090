import { useEffect, useState } from "react"
import './AsideSearchModal.css'
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { makeStyles } from '@mui/styles';
import axios from "axios";
import { Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";

const useStyles = makeStyles({
    myIcon: {
        color: 'white', // Change the color to your desired color
    },
});

const AsideSearchModal = ({formData, setFormData, submitSearch}) => {
    const [show, setShow] = useState(false);
    const classes = useStyles();
    const [marks, setMarks] = useState([]);
    const [models, setModels] = useState([]);
    const [showSections, setShowSections] = useState({
        marks: false, models: false, years: false
    });
    const [marksList, setMarksList] = useState([]);
    const [years, setYears] = useState({
        from: getYearsArray().reverse(),
        to: getYearsArray().reverse()
    });

    const list = [...getYearsArray().reverse()];

    useEffect(() => {
        axios('/api/cars/models')
            .then((res) => {
                const data = res.data.data;
                setMarksList([...data]);
                const uniqueMarks = new Set();
                const filteredArray = data.filter(item => {
                    if (!uniqueMarks.has(item.mark)) {
                        uniqueMarks.add(item.mark);
                        return true;
                    }
                    return false;
                });
                let mark = filteredArray.map((a) => a.mark);
                mark = mark.sort((a, b) => a.localeCompare(b));
                setMarks([...mark]);
            })
            .catch((err) => {

            })
    }, []);

    const handlers = {
        marksChange(newValue) {
            const selectModels = marksList.filter((m) => m.mark === newValue);
            const model = selectModels.map((a) => a.model);
            setModels([...model]);
            setShowSections((prev) => ({ ...prev, marks: false, models: true }))
            setFormData((prev) => ({ ...prev, mark: newValue.replaceAll(" ", "_") }));
        },
        modelsChange(newValue) {
            setFormData((prev) => ({ ...prev, model: newValue.replaceAll(" ", "_") }));
            setShowSections((prev) => ({ ...prev, models: false }))
        },
        condition(e) {
            setFormData((prev) => ({...prev, condition: e.target.value.replaceAll(" ", "_")}))
        },
        auction(e){
            setFormData((prev) => ({...prev, auction: e.target.value}))
        },
        search(){

        }
    }

    return (
        <div className={`aside-search-modal-container ${show ? 'show-aside' : 'hide-aside'}`}>
            <button className="show-hide-search-aside" onClick={() => setShow((prev) => !prev)}>{!show ? <SearchIcon className={classes.myIcon} /> : <ArrowBackIosIcon className={classes.myIcon} />}</button>
            <div className="search-filter-content">
            <div className="filter-sections-header" style={{ marginTop: "35px" }}>
                    <h5 className="search-filter-section-title">აუქციონი</h5>
                </div>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={handlers.auction}
                    >
                        <FormControlLabel value="iaai" control={<Radio />} label="Copart" />
                        <FormControlLabel value="copart" control={<Radio />} label="IAAI" />
                        <FormControlLabel value="" control={<Radio />} label="ყველა" />
                    </RadioGroup>
                </FormControl>
                <div className="filter-sections-header" style={{ marginTop: "35px" }}>
                    <h5 className="search-filter-section-title">მარკა</h5>
                    <Button style={{padding: "0", width: "35px", minWidth: "35px"}} variant="contained" onClick={() => setShowSections((prev) => ({ ...prev, marks: !prev.marks }))}><KeyboardArrowDownIcon /></Button>
                </div>
                <div className={`catalogue-marks-selector ${showSections.marks ? 'show-this-section' : 'hide-this-section'}`} >
                    <ul className="catalogue-marks-list">
                        {
                            showSections.marks ? (marks.map((mark, k) => {
                                return <li onClick={() => handlers.marksChange(mark)} key={k * 5}>{mark}</li>
                            })) : (
                                <li>{formData.mark !== "" ? formData.mark : "მონიშნეთ მარკა"}</li>
                            )
                        }
                    </ul>
                </div>
                <div className="filter-sections-header">
                    <h5 className="search-filter-section-title">მოდელი</h5>
                    <Button style={{padding: "0", width: "35px", minWidth: "35px"}} variant="contained" onClick={() => setShowSections((prev) => ({ ...prev, models: !prev.models }))} ><KeyboardArrowDownIcon /></Button>
                </div>
                <div className={`catalogue-marks-selector ${(showSections.models && models.length > 0) ? 'show-this-section' : 'hide-this-section'}`} >
                    <ul className="catalogue-marks-list">
                        {
                            models.length > 0 ? (
                                (formData.model !== "" && showSections.models === false) ? (
                                    <li>{formData.model}</li>
                                ) : (
                                    models.map((model, k) => {
                                        return <li onClick={() => handlers.modelsChange(model)} key={k * 5}>{model}</li>
                                    })
                                )
                            ) : (
                                <li>მარკა არ არის მონიშნული</li>
                            )
                        }
                    </ul>
                </div>
                <div className="filter-sections-header">
                    <h5 className="search-filter-section-title">წელი</h5>
                    <Button style={{padding: "0", width: "35px", minWidth: "35px"}} variant="contained" onClick={() => setShowSections((prev) => ({ ...prev, years: !prev.years }))} ><KeyboardArrowDownIcon /></Button>
                </div>
                <div className={`catalogue-years-selector ${showSections.years ? 'show-this-section' : 'hide-this-section'}`} >
                    <ul className="catalogue-year-list">
                        {
                            showSections.years ? (
                                years.from.map((y) => {
                                    return <li className={formData.yearsTo === y ? 'selected-year' : ''} onClick={() => {
                                        setYears((prev) => {
                                            const f = list.filter((year) => year >= y)
                                            return { ...prev, to: f }
                                        });
                                        setFormData((prev) => ({ ...prev, yearsFrom: y }));
                                    }} key={y}>{y}</li>
                                })
                            ) : (
                                formData.yearsFrom !== "" ? <li>{formData.yearsFrom}</li> : <li>დან</li>
                            )
                        }
                    </ul>
                    <ul className="catalogue-year-list">
                        {
                            showSections.years ? (
                                years.to.map((y) => {
                                    return <li className={formData.yearsTo === y ? 'selected-year' : ''} onClick={() => {
                                        setYears((prev) => {
                                            const f = list.filter((year) => year <= y)
                                            return { ...prev, from: f }
                                        });
                                        setFormData((prev) => ({ ...prev, yearsTo: y }));
                                    }} key={y}>{y}</li>
                                })
                            ) : (
                                formData.yearsTo !== "" ? <li>{formData.yearsTo}</li> : <li>მდე</li>
                            )
                        }
                    </ul>
                </div>
                <div className="filter-sections-header">
                    <h5 className="search-filter-section-title">ფასი</h5>
                </div>
                <div className="filter-section-prices">
                    <TextField
                        label="დან"
                        variant="standard"
                        style={{ width: "48%" }}
                        onBlur={(e) => setFormData((prev) => ({ ...prev, priceFrom: e.target.value }))}
                    />
                    <TextField
                        label="მდე"
                        variant="standard"
                        style={{ width: "48%" }}
                        onBlur={(e) => setFormData((prev) => ({ ...prev, priceTo: e.target.value }))}
                    />
                </div>
                <div className="filter-sections-header">
                    <h5 className="search-filter-section-title">გარბენი</h5>
                </div>
                <div className="filter-section-prices">
                    <TextField
                        label="დან"
                        variant="standard"
                        style={{ width: "48%" }}
                        onBlur={(e) => setFormData((prev) => ({ ...prev, millageFrom: e.target.value }))}
                    />
                    <TextField
                        label="მდე"
                        variant="standard"
                        style={{ width: "48%" }}
                        onBlur={(e) => setFormData((prev) => ({ ...prev, millageTo: e.target.value }))}
                    />
                </div>
                <div className="filter-sections-header">
                    <h5 className="search-filter-section-title">მდგომარეობა</h5>
                </div>
                <select className="form-control" onChange={(e) => setFormData((prev) => ({...prev, condition: e.target.value}))}>
                    <option>მდგომარეობა</option>
                    <option value={"GOOD"}>კარგი</option>
                    <option value={"MINOR_DAMAGE"}>მცირე დაზიანება</option>
                    <option value={"DAMAGE"}>დაზიანებული</option>
                    <option value={"OTHER"}>სხვა</option>
                </select>
                <div className="filter-sections-header">
                    <h5 className="search-filter-section-title">საწვავის ტიპი</h5>
                </div>
                <select className="form-control" onChange={(e) => setFormData((prev) => ({...prev, fuel: e.target.value}))}>
                    <option>საწვავის ტიპი</option>
                    <option value="DIESEL">დიზელი</option>
                    <option value="ELECTRIC">ელექტრო</option>
                    <option value="GAS">ბენზინი</option>
                    <option value="HYBRID">ჰიბრიდი</option>
                    <option value="PLUGIN_HYBRID">დატენვადი ჰიბრიდი</option>
                </select>
                <div className="filter-sections-header">
                    <h5 className="search-filter-section-title">ტრანსმისია</h5>
                </div>
                <select className="form-control" onChange={(e) => setFormData((prev) => ({...prev, transmission: e.target.value}))}>
                    <option>ტრანსმისია</option>
                    <option value="AUTOMATIC">ავტომატიკა</option>
                    <option value="MANUAL">მექანიკა</option>
                    <option value="OTHER">სხვა</option>
                </select>
                <div className="filter-sections-header">
                    <h5 className="search-filter-section-title">წამყვანი თვლები</h5>
                </div>
                <select className="form-control" onChange={(e) => setFormData((prev) => ({...prev, drive: e.target.value}))}>
                    <option>წამყვანი თვლები</option>
                    <option value='FRONT_DRIVE'>წინა</option>
                    <option value='REAR_DRIVE'>უკანა</option>
                    <option value='FOUR_X_FOUR_DRIVE'>4X4</option>
                    <option value='ALL_DRIVE'>All Drive</option>
                    <option value='OTHER'>სხვა</option>
                </select>
                <Button onClick={submitSearch} style={{width: "100%", marginTop: "40px"}} variant="contained"><SearchIcon/></Button>
            </div>
        </div>
    )
}

function getYearsArray() {
    const currentYear = new Date().getFullYear();
    const startYear = 1970;
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
        years.push(year);
    }

    return years;
}

export default AsideSearchModal