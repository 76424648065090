import {useEffect, useState} from "react";
import MenuIcon from '@mui/icons-material/Menu';
import './AsideModal.css'
import { makeStyles } from "@mui/styles";
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";
import axios from "axios";
import * as React from "react";

const useStyles = makeStyles({
    myIcon: {
        color: 'white', // Change the color to your desired color
    },
});

const AsideModal = ({isLoggedIn, role}) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    const logout = () => {
        axios.post('/api/logout')
            .then(() => {
                window.location.href = "/";
            })
            .catch(() => {

            })
    }

    return (
        <>
            <div className={`aside-search-modal-container ${(isOpen) ? 'show-aside' : 'hide-aside'}`}>
                <button className="show-hide-search-aside menu-btn" onClick={() => setIsOpen((prev) => !prev)}>{!isOpen ? <MenuIcon className={classes.myIcon} /> : <ArrowBackIosIcon className={classes.myIcon} />}</button>
                <div className="authed-page-navigation">
                    <Link to={"/"}>მთავარი</Link>
                    <Link to={'/catalogue'}>კატალოგი</Link>
                    <Link to={"/auto-market"}>მარკეტი</Link>
                    <Link to={"/register-as-dealer"}>დილერად რეგისტრაცია</Link>
                    <Link to={"/contact"}>კონტაქტი</Link>
                    {(isLoggedIn && role != "admin") && <>
                        <Link to="/my-page/watch">ფავორიტები</Link>
                        <Link to="/my-page/consultations">ჩემი მოთხოვნები</Link>
                        <Link to="/my-page/buy-now">შეკვეთები</Link>
                        <div style={{display: "flex", flexDirection: "column", marginTop: "90px"}}>
                            <span style={{fontSize: "17px", marginBottom: "8px", cursor: "pointer"}}>Edit Profile</span>
                            <span onClick={logout} style={{fontSize: "17px", cursor: "pointer"}}>Log Out</span>
                        </div>
                    </>}
                    {
                        role == "admin" && (
                            <>
                            <hr/>
                                <Link to={"/admin/consultation"}>ავტომობილის მოთხოვნა</Link>
                                <Link to={"/admin/buy-now"}>ახლავე ყიდვა</Link>
                                <Link to={"/admin/max-bid"}>მაქს. ბიდი</Link>
                                <Link to={"/admin/dealers"}>დილერები</Link>
                            </>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default AsideModal