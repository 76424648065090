import { useEffect, useState } from "react";
import DataTable from "../../Common/DataTable/DataTable";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const Dealers = () => {
    const headers = ["ID", "Name", "Email", "Phone", "Cars per month", "Status"];
    const showParams = ["id", "name", "email", "phone", "car_per_month", "status"];
    const [income, setIncome] = useState([]);
    const [page, setPage] = useState(1);
    const [active, setActive] = useState({});
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [notification, setNotification] = useState({ show: false, title: "", body: "", centered: false, size: "md" });
    const [loading, setLoading] = useState(false);
    const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);

    const handlers = {
        search(v) {
            setKeyword(v)
        }
    }

    const rowActions = [

    ]

    useEffect(() => {
        axios(`/api/dealer/registration?page=${page}&limit=${limit}&keyword=${keyword}`)
            .then((response) => {
                const data = response.data.data;
                setIncome([...data]);
                setTotal(response.data.total);
            })
            .catch((err) => {
                setNotification({
                    show: true,
                    title: "Error",
                    body: err.response.data.message,
                    centered: false,
                    size: "md"
                });
                setLoading(false)
            })
    }, [limit, page, keyword]);

    return (
        <>
            <div className="datatable-container">
                <DataTable
                    withoutSearchButton={true}
                    headers={headers}
                    searchHandler={handlers.search}
                    title={"Dealers"}
                    actionButtons={[]}
                    limit={limit}
                    setLimit={setLimit}
                    limitControl={true}
                    data={income}
                    showParams={showParams}
                    pageChanged={page}
                    setPageChanged={setPage}
                    total={total}
                    rowActions={false}
                    rowActionsList={rowActions}
                    setActiveRow={setActive}
                    selects={false}
                    dateRange={false}
                    withSelectFilter={false}
                    withImage={false}
                />
            </div>

            <Modal show={notification.show} onHide={() => setNotification((prev) => ({ ...prev, show: false }))}
                size={notification.size}>
                <Modal.Header><h3>{notification.title}</h3></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
                <Modal.Body><Button variant={"contained"} onClick={() => setNotification((prev) => ({
                    ...prev,
                    show: false
                }))}>Ok</Button></Modal.Body>
            </Modal>
            <Modal show={showDeleteProductModal} onHide={() => setShowDeleteProductModal(false)} size={"md"}>
                <Modal.Header><h3>პროდუქტის წაშლა</h3></Modal.Header>
                <Modal.Body><p>ნამდვილად გსურთ პროდუქტის წაშლა?</p></Modal.Body>
                <Modal.Body>
                    <div className={"modal-header-buttons"}>
                        <LoadingButton
                            loading={loading}
                            startIcon={<CheckIcon />}
                            variant="contained"
                            type={"submit"}
                            onClick={handlers.delete}
                            color={"error"}
                            style={{ marginRight: "20px" }}
                        >
                            დიახ
                        </LoadingButton>
                        <Button
                            startIcon={<CloseIcon />}
                            variant="contained"
                            onClick={() => setShowDeleteProductModal(false)}
                        >
                            არა
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

function formatDate(current) {
    return `${current.getFullYear()}-${current.getMonth() + 1 < 10 ? `0${current.getMonth() + 1}` : current.getMonth() + 1}-${current.getDate() + 1 < 10 ? `0${current.getDate() + 1}` : current.getDate() + 1}`
}

export default Dealers