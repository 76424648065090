import { Link } from "react-router-dom";

const MarketCars = ({ data, idSetter, isSlider, editCar, role }) => {
    return (
        <>
            {role == "admin" && <button className="market-edit-button" onClick={() => editCar(data.id)}><img src="https://static-00.iconduck.com/assets.00/edit-icon-2048x2048-6svwfwto.png" alt="edit icon"/></button>}
            <Link to={{pathname: `/auto-market/${data.id}`, state: {...data}}} className={isSlider ? "slider-items-car-container" : ""} style={{width: "100%"}}>
                <div className='car-item-container' onClick={() => {
                    if(typeof idSetter !== "undefined") idSetter();
                }}>
                    <div className='car-item-image' style={{ backgroundImage: `url('${data.profile_image}')` }}></div>
                    <div className='car-details-container'>
                        <h5 className='car-details-name'>{`${data.year} ${data.mark} ${data.model}`}</h5>
                        <hr className='hr' />
                        <div className='car-details-city-and-bid'>
                            <div>
                                <p className='car-details-location car-details-primary-damage'><strong style={{ color: 'black' }}>ქალაქი: </strong>{data.location}</p>
                                {/* <p className='car-details-buy-now-amount'><strong style={{ color: 'black' }}>იყიდე ახლავე: </strong> ${data.price}</p> */}
                            </div>
                            <div className='car-details-bid-section'>
                                <p className='car-details-high-bid'>{data.price}</p>
                                <p className="car-details-currency">USD</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
}

export default MarketCars