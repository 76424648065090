const SearchContainer = () => {
    return (
        <div id="map" style={{paddingTop: "90px"}}>
            <div className='map-container'>
                <iframe id="container-tracking" className='tracking-map' style={{ height: "1100px" }} src="https://www.vsbrothers.com/public/tracking" width="100%" height="680px" frameborder="0" align="middle" scrolling="No" allowfullscreen="Yes"></iframe>
            </div>
        </div>
    )
}

export default SearchContainer