import { useEffect, useState } from "react";
import DataTable from "../Common/DataTable/DataTable";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const WatchList = () => {
    const headers = ["ID", "Mark & Model", "Year", "Odometer", "Damage", "Key", "State", "VIN", ""];
    const showParams = ["lot", "model", "year", "odometer_value_km", "primary_damage", "key_fob", "vehicle_state", "vin"];
    const [income, setIncome] = useState([]);
    const [page, setPage] = useState(1);
    const [active, setActive] = useState({});
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [keyword, setKeyword] = useState("");
    const [notification, setNotification] = useState({ show: false, title: "", body: "", centered: false, size: "md" });
    const [loading, setLoading] = useState(false);
    const [showDeleteProductModal, setShowDeleteProductModal] = useState(false);

    const handlers = {
        search(v) {
            setKeyword(v)
        },
        delete() {
            setLoading(true);
            axios.delete(`/api/income/${active.id}`)
                .then(() => {
                    setShowDeleteProductModal(false);
                    setLoading(false);
                    axios(`/api/income?page=${page}&limit=${limit}&keyword=${keyword}`)
                        .then((response) => {
                            const data = response.data.data;
                            setIncome([...data]);
                            setTotal(response.data.total);
                        })
                        .catch((err) => {
                            setNotification({
                                show: true,
                                title: "Error",
                                body: err.response.data.message,
                                centered: false,
                                size: "md"
                            });
                            setLoading(false)
                        })
                })
                .catch((err) => {
                    setNotification({
                        show: true,
                        title: "Error",
                        body: err.response.data.message,
                        centered: false,
                        size: "md"
                    });
                    setShowDeleteProductModal(false);
                    setLoading(false)
                })
        }
    }

    const rowActions = [
        {
            name: "Unwatch",
            id: 1003,
            action: () => {
                setShowDeleteProductModal(true)
            }
        }
    ]

    useEffect(() => {
        axios(`/api/users/watch-list?page=${page}&limit=${limit}&keyword=${keyword}`)
            .then((response) => {
                const data = response.data.data;
                setIncome([...data]);
                setTotal(response.data.total);
            })
            .catch((err) => {
                setNotification({
                    show: true,
                    title: "Error",
                    body: err.response.data.message,
                    centered: false,
                    size: "md"
                });
                setLoading(false)
            })
    }, [limit, page, keyword]);

    return (
        <>
            <div className="datatable-container">
                <DataTable
                    withoutSearchButton={true}
                    headers={headers}
                    searchHandler={handlers.search}
                    title={"Watch List"}
                    actionButtons={[]}
                    limit={limit}
                    setLimit={setLimit}
                    limitControl={true}
                    data={income}
                    showParams={showParams}
                    pageChanged={page}
                    setPageChanged={setPage}
                    total={total}
                    rowActions={true}
                    rowActionsList={rowActions}
                    setActiveRow={setActive}
                    selects={false}
                    dateRange={false}
                    withSelectFilter={false}
                    withImage={true}
                />
            </div>

            <Modal show={notification.show} onHide={() => setNotification((prev) => ({ ...prev, show: false }))}
                size={notification.size}>
                <Modal.Header><h3>{notification.title}</h3></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
                <Modal.Body><Button variant={"contained"} onClick={() => setNotification((prev) => ({
                    ...prev,
                    show: false
                }))}>Ok</Button></Modal.Body>
            </Modal>
            <Modal show={showDeleteProductModal} onHide={() => setShowDeleteProductModal(false)} size={"md"}>
                <Modal.Header><h3>პროდუქტის წაშლა</h3></Modal.Header>
                <Modal.Body><p>ნამდვილად გსურთ პროდუქტის წაშლა?</p></Modal.Body>
                <Modal.Body>
                    <div className={"modal-header-buttons"}>
                        <LoadingButton
                            loading={loading}
                            startIcon={<CheckIcon />}
                            variant="contained"
                            type={"submit"}
                            onClick={handlers.delete}
                            color={"error"}
                            style={{ marginRight: "20px" }}
                        >
                            დიახ
                        </LoadingButton>
                        <Button
                            startIcon={<CloseIcon />}
                            variant="contained"
                            onClick={() => setShowDeleteProductModal(false)}
                        >
                            არა
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

function formatDate(current) {
    return `${current.getFullYear()}-${current.getMonth() + 1 < 10 ? `0${current.getMonth() + 1}` : current.getMonth() + 1}-${current.getDate() + 1 < 10 ? `0${current.getDate() + 1}` : current.getDate() + 1}`
}

export default WatchList