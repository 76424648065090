import styles from "./Offer.module.css";

const Offer = (props) => {
  return (
    <div className={styles["offer-content"]}>
      <div className={styles['icon-circle']} style={{background: `${props.bColor}`}}>{props.icon}</div>
      <p className={styles["offer-title"]}>{props.offerTitle}</p>
      <p className={styles["offer-text"]}>{props.offerText}</p>
    </div>
  );
};

export default Offer;
