const EvacuatorIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${props.iconWidth}px`}
      fill={(props.color)? props.color : "black" }
      viewBox="0 0 47.831 47.831"
    >
      <g
        id="vuesax_linear_truck-tick"
        dataname="vuesax/linear/truck-tick"
        transform="translate(-108 -252)"
      >
        <g id="truck-tick" transform="translate(108 252)">
          <path
            id="Vector"
            d="M25.908,0V19.93a4,4,0,0,1-3.986,3.986H0V11.2a7.77,7.77,0,0,0,6.158,2.75,7.66,7.66,0,0,0,5.182-2.113,6.749,6.749,0,0,0,1.535-1.893A7.762,7.762,0,0,0,13.951,5.8,7.938,7.938,0,0,0,11.24,0Z"
            transform="translate(3.986 3.986)"
            fill="none"
            stroke="#292d32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Vector-2"
            dataname="Vector"
            d="M39.859,17.937v5.979a5.971,5.971,0,0,1-5.979,5.979H31.887a3.986,3.986,0,1,0-7.972,0H15.944a3.986,3.986,0,1,0-7.972,0H5.979A5.971,5.971,0,0,1,0,23.916V17.937H21.923a4,4,0,0,0,3.986-3.986V0h3.667a4.019,4.019,0,0,1,3.468,2.013l3.408,5.959H33.88a2,2,0,0,0-1.993,1.993v5.979a2,2,0,0,0,1.993,1.993Z"
            transform="translate(3.986 9.965)"
            fill="none"
            stroke="#292d32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Vector-3"
            dataname="Vector"
            d="M7.972,3.986A3.986,3.986,0,1,1,3.986,0,3.986,3.986,0,0,1,7.972,3.986Z"
            transform="translate(11.958 35.873)"
            fill="none"
            stroke="#292d32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Vector-4"
            dataname="Vector"
            d="M7.972,3.986A3.986,3.986,0,1,1,3.986,0,3.986,3.986,0,0,1,7.972,3.986Z"
            transform="translate(27.901 35.873)"
            fill="none"
            stroke="#292d32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Vector-5"
            dataname="Vector"
            d="M7.972,5.979V9.965H1.993A2,2,0,0,1,0,7.972V1.993A2,2,0,0,1,1.993,0H4.564Z"
            transform="translate(35.873 17.937)"
            fill="none"
            stroke="#292d32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <g id="Group" transform="translate(1.991 1.992)">
            <path
              id="Vector-6"
              dataname="Vector"
              d="M15.946,7.794a7.762,7.762,0,0,1-1.076,4.145,6.749,6.749,0,0,1-1.535,1.893,7.66,7.66,0,0,1-5.182,2.113A7.77,7.77,0,0,1,2,13.195a6.971,6.971,0,0,1-.737-.977A7.7,7.7,0,0,1,0,8.153a7.974,7.974,0,0,1,15.944-.359Z"
              transform="translate(0 0)"
              fill="none"
              stroke="#292d32"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
            />
            <path
              id="Vector-7"
              dataname="Vector"
              d="M0,2.113,2.013,4.026,6.178,0"
              transform="translate(4.865 5.92)"
              fill="none"
              stroke="#292d32"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="3"
            />
          </g>
          <path
            id="Vector-8"
            dataname="Vector"
            d="M0,0H47.831V47.831H0Z"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};

export default EvacuatorIcon