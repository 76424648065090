import styles from './FrequencyQuestions.module.css'
import Questions from './Questions'

const FrequencyQuestions = () => {
    return (
        <div className={styles['frequency-questions-container']}>
            <h2 className={styles['section-title']}>ხშირად დასმული კითხვები</h2>
            <Questions question="ვინ ვართ ჩვენ?" answer="10 წლიანი გამოცდილებით კომპანია “ქარექსპრესი” ერთ-ერთი ყველაზე დიდი ავტოიმპორტიორია კავკასიაში. კომპანია ახორციელებს ავტომობილებისა და ნებისმიერი ტიპის სპეც-ტექნიკის, ასევე ავტონაწილებისა და აქსესუარების იმპორტს ამერიკიდან." />
            <Questions question="რატომ უნდა ვისარგებლო თქვენი მომსახურებით?" answer="კომპანია “ქარექსპრესი” ლიცენზირებული ავტო იმპორტიორია, რაც საშუალებას აძლევს მონაწილეობა მიიღოს მსოფლიოს წამყვან საავტომობილო აუქციონებზე. კომპანია, დაარესების დღიდან, მისი განვითარების გზაზე, დაკომპლექტდა მაღალკვალიფიციური გუნდით და დღესდღეისობით საქართველოს მასშტაბით 30-მდე ფილიალს და საქართველოს ფარგლებს გარეთ 10-მდე წარმომადგენლობას ითვლის. კომპანიის მიერ იმპორტირებული მანქანების რაოდენობა 100 000 ს აჭარბებს." />
            <Questions question="რა დრო ჭირდება ტრანსპორტირებას?" answer="ტრანსპორტირების ვადა საშუალოდ მოიცავს 1.5-2.5 თვეს და დამოკიდებულია ავტომობილის მდებარეობაზე ამერიკაში." />
            <Questions question="მსურს 1-ზე მეტი ავტომობილის შეძენა, როგორ მოვიქცე?" answer="კომპანიის სერვისებით სარგებლობა შეუძლიათ, როგორც ავტომობილის შეძენის მსურველებს, ასევე ავტო იმპორტის ბიზნესით დაინტერესებულ ადამიანებს. ჩვენთან თანამშრომლობით თქვენ შეგიძლიათ მიიღოთ თქვენზე მორგებული შეთავაზებები, როგორც ტრანსპორტირების ასევე დაფინანსების კუთხით. დილერად დასარეგისტრირებლად ეწვიეთ ჩვენს გვერდს ან დაგვიკავშირდით 2 424 100" />
        </div>
    )
}

export default FrequencyQuestions