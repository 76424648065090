import { createSlice } from "@reduxjs/toolkit";

const initialUsers = {
    isLoggedIn: null,
    username: "",
    name: "",
    surname: "",
    email: "",
    role: "",
    initials: ""
}

const user = createSlice({
    name: "users",
    initialState: initialUsers,
    reducers: {
        changeIsLoggedIn(state, action){
            state.isLoggedIn = action.payload
        },
        saveUserData(state, action){
            state.role = action.payload.role;
            state.username = action.payload.username;
            state.name = action.payload.name;
            state.surname = action.payload.surname;
            state.email = action.payload.email;
            state.phone = action.payload.phone;
            state.initials = `${action.payload.name.slice(0, 1).toUpperCase()}${action.payload.surname.slice(0, 1).toUpperCase()}`;
        },
    }
})

export const usersActions = user.actions;

export default user.reducer