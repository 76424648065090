import Offer from "./Offer";
import styles from "./DealerServices.module.css";
import RegAsDealer from "./RegAsDealer";
import EvacuatorIcon from "./EvacuatorIcon";
import Cach from "./Cach";

const DealerServices = () => {
  return (
    <div className={styles["dealer-services-container"]}>
      <h2 className={styles["section-title"]}>
        ჩამოიყვანე ავტომობილი ამერიკის <br /> უმსხვილესი აუქციონებიდან
      </h2>
      <p className={styles["services-description"]}>
        გახდი ჩვენი დილერი, ქარექსპრესი გაძლევს საშუალებას შუამავლის გარეშე{" "}
        <br />
        შეიძინო ავტომობილები ამერიკის უმსხვილესი აუქციონებიდან
      </p>
      <div className={styles["services-container"]}>
        <Offer
          offerTitle="მიიღე აუქციონის კოდები"
          offerText="თავად მიიღე მონაწილეობა ამერიკის უმსხვილეს აუქციონებზე, შეიძინე ავტომობილები და აქციე ინტერესი შემოსავლის წყაროდ ქარექსპრესთან ერთად."
          bColor="#E9EFF5"
          icon={<RegAsDealer iconWidth="50" color="black" />}
        />
        <Offer
          offerTitle="ისარგებლე სპეციალური ტრანსპორტირების ფასები"
          offerText="მიიღე ტრანსპორტირების შენზე მორგებული ფასები,ჩვენ ჩვენი პარტნიორი დილერებისთვის გვაქვს განსაკუთრებული შეთავაზება ტრანსპორტირებაზე."
          bColor="#E9EFF5"
          icon={<EvacuatorIcon iconWidth="50" color="black" />}
        />
        <Offer
          offerTitle="დაიმტკიცე დაფინანსება"
          offerText="ისარგებლე შენზე მორგებული დაფინანსების პირობებით, სურვილის შემთხვევაში დაიფინანსე ავტომობილის ღირებულების, ტრანსპორტირების, განბაღებისა და შეკეთების თანხა."
          bColor="#E9EFF5"
          icon={<Cach iconWidth="50" color="black" />}
        />
      </div>
    </div>
  );
};

export default DealerServices;
