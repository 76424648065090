const Cach = (props) => {
  return (
    <svg
      width="43"
      height="31"
      viewBox="0 0 43 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.91797 5.70829C1.91797 4.66953 2.33062 3.67331 3.06513 2.93879C3.79965 2.20427 4.79587 1.79163 5.83464 1.79163H37.168C38.2067 1.79163 39.203 2.20427 39.9375 2.93879C40.672 3.67331 41.0846 4.66953 41.0846 5.70829V25.2916C41.0846 26.3304 40.672 27.3266 39.9375 28.0611C39.203 28.7956 38.2067 29.2083 37.168 29.2083H5.83464C4.79587 29.2083 3.79965 28.7956 3.06513 28.0611C2.33062 27.3266 1.91797 26.3304 1.91797 25.2916V5.70829Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5 21.375C24.7447 21.375 27.375 18.7447 27.375 15.5C27.375 12.2553 24.7447 9.625 21.5 9.625C18.2553 9.625 15.625 12.2553 15.625 15.5C15.625 18.7447 18.2553 21.375 21.5 21.375Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.91797 9.62496C3.9955 9.62496 5.98794 8.79966 7.45697 7.33063C8.92601 5.86159 9.7513 3.86916 9.7513 1.79163M33.2513 29.2083C33.2513 27.1308 34.0766 25.1383 35.5456 23.6693C37.0147 22.2003 39.0071 21.375 41.0846 21.375"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Cach;
