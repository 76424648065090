import Carousel from 'react-bootstrap/Carousel'
import { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Grid, Paper, Skeleton, TextField } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import { Modal } from "react-bootstrap";
import Car from "../Components/Car/Car";
import axios from "axios";
import { useSelector } from "react-redux";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import CalculateIcon from '@mui/icons-material/Calculate';
import CircularProgressWithLabel from '../Common/Progress';
import { useSpringCarousel } from 'react-spring-carousel';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const CarDetails = () => {
    const [progress, setProgress] = useState(0);
    const [watch, setWatch] = useState(false);
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [cars, setCars] = useState([]);
    const [data, setData] = useState(null);
    let [details, setDetails] = useState({}); //included.filter((i) => i.id === id)
    const [images, setImages] = useState([]);//details[0].attributes.images
    const [notification, setNotification] = useState({ show: false, title: "", body: "" });
    const itemsArray = new Array(8).fill("empty");
    const [formData, setFormData] = useState({ name: "", phone: "" });
    const [showConsultationModal, setShowConsultationModal] = useState(false)
    const [id, setId] = useState(window.location.pathname.slice(-8));
    const [attributes, setAttributes] = useState({});
    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };
    const params = useParams();
    const marketType = window.location.search;
    const [maxBid, setMaxBid] = useState({ amount: "", show: false });
    const [buyNow, setBuyNow] = useState(false);
    const user = useSelector((state) => state.user);
    const [calculator, setCalculator] = useState({
        port: {},
        auction: [],
        car_type: [],
        destination: [],
        price: "0.00"
    });
    const [calculating, setCalculating] = useState(false);
    const windowWidth = window.innerWidth;
    let slideToShow = 3.8;
    const isMarket = marketType === '?type=market';

    if (windowWidth < 500) {
        slideToShow = 1
    } else if (windowWidth < 630) {
        slideToShow = 1.3
    } else if (windowWidth < 870) {
        slideToShow = 1.8
    } else if (windowWidth < 1200) {
        slideToShow = 2.8
    }

    const menuStyle = {
        textAlign: 'left', // Set text alignment to right
    };

    const [calculatorForm, setCalculatorForm] = useState({
        port: "",
        auction: "",
        car_type: "",
        destination: "",
    })

    const handlers = {
        priceCalculate() {
            setCalculating(true);
            const timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress < 99 ? prevProgress + 1 : 99));
            }, 50);
            axios(`/api/calculate?auction=${calculatorForm.auction}&port=${calculatorForm.port}&car_type=${calculatorForm.car_type}&destination=${calculatorForm.destination}`)
                .then((res) => {
                    const data = res.data.price;
                    setProgress(0);
                    setCalculating(false);
                    setCalculator((prev) => {
                        return { ...prev, price: data }
                    })
                    clearInterval(timer);
                })
                .catch((err) => {
                    setProgress(0);
                    setCalculating(false);
                    setNotification({ show: true, title: "შეცდომა", body: "რაღაც გაუთვალისწინებელი მოხდა" });
                    clearInterval(timer);
                })
        },
        watch() {
            setWatch(!watch);
            axios.put(`/api/watch`, { ...attributes, id: id })
                .then((res) => {
                    const status = res.data.watched;
                    setWatch(status);
                })
                .catch((err) => {
                    setNotification({ show: true, title: "შეცდომა", body: "რაღაც გაუთვალისწინებელი მოხდა" })
                })
        },
        consultation(e) {
            e.preventDefault();
            axios.post('/api/cars/consultation', { ...formData, data: { ...attributes }, mark: details[0].attributes.maker, model: details[0].attributes.model, year: details[0].attributes.year, vin: details[0].attributes.vin_code })
                .then(() => {
                    setNotification({ show: true, title: "მოთხოვნა წარმატებით დამუშავდა", body: "კონსულტაციის მოთხოვნა გაგზავნილია. დაელოდეთ ჩვენი წარმომადგენლის ზარს" });
                    setFormData({ name: "", phone: "" });
                    setMaxBid({ show: false, amount: "" });
                    setShowConsultationModal(false);
                })
                .catch(() => {
                    setNotification({ show: true, title: "შეცდომა", body: "Something went wrong" });
                    setFormData({ name: "", phone: "" });
                    setShowConsultationModal(false);
                    setMaxBid({ show: false, amount: "" })
                })
        },
        maxBid() {
            if (user.isLoggedIn) {
                axios.post("/api/users/cars/max-bids", { vin: attributes.vin_code, bid: maxBid.amount, lot: id, mark: attributes.maker.replaceAll("_", " "), model: attributes.model.replace(`${attributes.maker}_`, ""), year: attributes.year, data: attributes })
                    .then(() => {
                        setNotification({ show: true, title: "მოთხოვნა წარმატებით დამუშავდა", body: "მაქს. ბიდის მოთხოვნა გაიგზავნა. დაელოდეთ ჩვენი წარმომადგენლის ზარს" });
                        setFormData({ name: "", phone: "" });
                        setMaxBid({ show: false, amount: "" });
                    })
                    .catch(() => {
                        setNotification({ show: true, title: "შეცდომა", body: "რაღაც გაუთვალისწინებელი მოხდა" })
                        setFormData({ name: "", phone: "" });
                        setShowConsultationModal(false);
                    })
            } else {
                setNotification({ show: true, title: "საჭიროა ავტორიზაცია", body: "გთხოვთ გაიაროთ ავტორიზაცია მაქს. ბიდის დასადებად" });
            }
        },
        placeBuyNowRequest() {
            axios.post("/api/request/buy-now", { vin: attributes.vin_code, lot: id, mark: attributes.maker.replaceAll("_", " "), model: attributes.model.replace(`${attributes.maker}_`, ""), year: attributes.year, data: attributes })
                .then(() => {
                    setNotification({ show: true, title: "მოთხოვნა წარმატებით დამუშავდა", body: "ყიდვის მოთხოვნა გაგზავნილია. დაელოდეთ ჩვენი წარმომადგენლის ზარს" });
                    setBuyNow(false);
                })
                .catch(() => {
                    setNotification({ show: true, title: "შეცდომა", body: "რაღაც გაუთვალისწინებელი მოხდა" })
                    setBuyNow(false);
                })
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        marketType === '?type=market' ? (
            axios(`/api/market/${params.id}`)
                .then((response) => {
                    const res = response.data.data;
                    setData({ ...res });
                    setImages(JSON.parse(res.images));
                    setAttributes({ ...res });
                })
        ) : (
        axios(`/api/lot/${params.id}`)
            .then((response) => {
                const res = response.data.data;
                setData({ ...res });
                const included = res.included;
                const d = included.filter((i) => i.id === id);
                axios.post(`/api/cars`, { currency: "USD", limit: 8, sort: "-created_at", mark: d[0].attributes.maker, model: d[0].attributes.model })
                    .then((rs) => {
                        setCars(rs.data.data);
                        setLoading(false);
                    })
                setDetails([...d]);
                setImages(d[0].attributes.images);
                setAttributes({ ...d[0].attributes });
            }))

        axios(`/api/watch/${params.id}`)
            .then((res) => {
                const status = res.data.watch;
                setWatch(status);
            })
            .catch((err) => {
                setNotification({ show: true, title: "შეცდომა", body: "რაღაც გაუთვალისწინებელი მოხდა" })
            })
        axios.get("/api/ports")
            .then((res) => {
                setCalculator({
                    auction: ["IAAI", "COPART"],
                    destination: [...res.data.data.destination_ports],
                    car_type: [...res.data.data.car_types],
                    port: { ...res.data.data.ports },
                    price: "0.00"
                })
            })
            .catch(() => {
                setNotification({ show: true, title: "შეცდომა", body: "კალკულატორი მონაცემები ვერ ჩაიტვირთა" })
            })
    }, [id]);

    const ItemsToRender = !loading ? cars.map((e) => {
        return {
            id: e.id,
            renderItem: <Car data={e} isSlider={true} />
        }
    }) : itemsArray.map((i, k) => {
        return {
            id: k,
            renderItem: <div style={{ width: "100%" }}>
                <Skeleton animation="wave" variant="rectangular" style={{ width: "100%", height: "220px", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }} />
                <div style={{ padding: "8px" }} className="car-details-container">
                    <Skeleton variant="text" style={{ width: "50%" }} sx={{ fontSize: '17px' }} />
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Skeleton variant="text" style={{ width: "61px" }} sx={{ fontSize: '14px' }} />
                        <Skeleton variant="text" style={{ width: "71px", marginLeft: "5px" }} sx={{ fontSize: '14px' }} />
                    </div>
                    <hr className="hr" style={{ margin: "8px 0px", borderColor: "#bcbcbc" }} />
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                        <div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Skeleton variant="text" style={{ width: "25px" }} sx={{ fontSize: '14px' }} />
                                <Skeleton variant="text" style={{ width: "50px", marginLeft: "5px" }} sx={{ fontSize: '14px' }} />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Skeleton variant="text" style={{ width: "75px" }} sx={{ fontSize: '14px' }} />
                                <Skeleton variant="text" style={{ width: "55px", marginLeft: "5px" }} sx={{ fontSize: '14px' }} />
                            </div>
                        </div>
                        <div>
                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                <Skeleton variant="text" style={{ width: "61px" }} sx={{ fontSize: '16px' }} />
                                <Skeleton variant="text" style={{ width: "71px" }} sx={{ fontSize: '19px' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
    });

    const { carouselFragment, slideToPrevItem, slideToNextItem } = useSpringCarousel({
        itemsPerSlide: slideToShow,
        withLoop: true,
        items: ItemsToRender,
    })

    return (
        <div className={"page-content-container"}>
            <div className={"slider-content"}>
                <Carousel fade className={"slider-box"} activeIndex={index} onSelect={handleSelect}>
                    {
                        images.length > 0 ? images.map((i, k) => {
                            return (
                                <Carousel.Item key={k * 100} interval={5000}>
                                    <img src={i} alt={"vehicle"} className={"slider-image-item"} />
                                </Carousel.Item>
                            )
                        }) : <Carousel.Item>
                            <Skeleton variant={"rectangular"} animation={"wave"} />
                        </Carousel.Item>
                    }
                </Carousel>
                <div className={"slider-items-list"}>
                    {
                        images.length > 0 && images.map((i, k) => {
                            return (
                                <div key={k * 1500} className={`slider-items-item ${k === index ? 'active-slide' : ''}`}
                                    style={{ backgroundImage: `url('${i}')` }} onClick={() => setIndex(k)}></div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={"car-details-description"}>
                <div className='set-width'>
                    <h3 className='car-details-title'>{data !== null ? (!isMarket ? data.data.attributes.name : `${data.year} ${data.model} ${data.mark}`) : <Skeleton variant={"text"} style={{ width: "150px" }} sx={{ fontSize: "20px" }} />}</h3>
                    {data !== null && <h6><span
                        style={{ color: "gray" }}>მდებარეობა:</span>{!isMarket ? ` ${details[0].attributes.city}, ${details[0].attributes.region}` : `${data.location}`}
                    </h6>}
                    {(data !== null && !isMarket) && <h6><span
                        style={{ color: "gray" }}>დოკუმენტის ტიპი:</span>{` ${details[0].attributes.ownership_document_type}`}
                    </h6>}
                    {/*<h6><span style={{color: "gray"}}>Primary Damage:</span>{` ${}`}</h6>*/}

                    {data !== null && <div className={"car-descriptions-container"}>
                        <h4 style={{ textAlign: "left", marginBottom: "35px" }}>დეტალები</h4>
                        <div><h6 style={{ color: "gray" }}>VIN:</h6>
                            <h6>{!isMarket ? ` ${details[0].attributes.vin_code.slice(0, -6)}` : ` ${data.vin.slice(0, -6)}`}<span
                                style={{ color: "red" }}>{isMarket ? data.vin.slice(-6) : details[0].attributes.vin_code.slice(-6)}</span></h6></div>
                        <div><h6 style={{ color: "gray" }}>გარბენი:</h6>
                            <h6>{isMarket ? `${data.odometer} KM` : ` ${details[0].attributes.odometer_value_km} KM (${details[0].attributes.odometer_value_state.replaceAll("_", " ")})`}</h6>
                        </div>
                        {!isMarket && <div><h6 style={{ color: "gray" }}>გასაღები:</h6><h6>{` ${details[0].attributes.key_fob === "PRESENT" ? "დიახ" : "არა"}`}</h6></div>}
                        <div><h6 style={{ color: "gray" }}>ძრავი:</h6>
                            <h6>{!isMarket ? ` ${(details[0].attributes.engine_volume_cm / 1000).toFixed(1)}L` : `${data.engine}L`}</h6></div>
                        <div><h6 style={{ color: "gray" }}>საწვავი:</h6><h6>{!isMarket ? ` ${details[0].attributes.fuel_type}` : data.fuel_type}</h6>
                        </div>
                        <div><h6 style={{ color: "gray" }}>ტრანსმისია:</h6>
                            <h6>{!isMarket ? ` ${details[0].attributes.transmission}` : ` ${data.transmission}`}</h6></div>
                        {!isMarket && <div><h6 style={{ color: "gray" }}>წამყვანი:</h6>
                            <h6>{` ${details[0].attributes.drive_type.replaceAll("_", " ")}`}</h6></div>}
                        <div><h6 style={{ color: "gray" }}>მარკა:</h6><h6>{!isMarket ? ` ${details[0].attributes.maker}` : ` ${data.mark}`}</h6></div>
                        <div><h6 style={{ color: "gray" }}>მოდელი:</h6>
                            <h6>{!isMarket ? ` ${details[0].attributes.model.replace(details[0].attributes.maker, "").replaceAll("_", " ")}` : data.model}</h6>
                        </div>
                        <div><h6 style={{ color: "gray" }}>წელი:</h6><h6>{!isMarket ? ` ${details[0].attributes.year}` : data.year}</h6></div>
                        <div><h6 style={{ color: "gray" }}>ფერი:</h6> <h6>{!isMarket ? ` ${details[0].attributes.color}` : data.color}</h6></div>
                        {!isMarket && <>
                            <div><h6 style={{ color: "gray" }}>მგლობელი:</h6> <h6>{` ${details[0].attributes.vin_code}`}</h6>
                            </div>
                            <div><h6 style={{ color: "gray" }}>გამყიდველი:</h6><h6>{` ${details[0].attributes.vin_code}`}</h6>
                            </div>
                        </>}
                    </div>}

                </div>
                <div className={"high-bid-amount hide-watch"}>
                    {!watch && <button className={"add-to-watch-list"} onClick={handlers.watch}>დამატება</button>}
                    {watch && <button className={"remove-watch"} onClick={handlers.watch}>წაშლა</button>}
                </div>
                <div className={"high-bid-amount show-watch"}>
                    {!watch && <button className={"add-to-watch-list"} onClick={handlers.watch}><VisibilityIcon /></button>}
                    {watch && <button className={"remove-watch"} onClick={handlers.watch}><VisibilityOffIcon /></button>}
                </div>
            </div>
            <div className={"consultation-section"}>
                <div className={"sales-manager-account"}>
                    <PersonIcon style={{ fontSize: "50px" }} />
                    <p className='consultation-text-hide'>მოითხოვე კონსულტაცია</p>
                </div>
                <Button variant={"contained"} onClick={() => setShowConsultationModal(true)}>კონსულტაცია</Button>
            </div>
            <div className={"car-details-actions-section"}>
                {!isMarket && <div className={"car-condition-details"}>
                    <ul className={"condition-details"}>
                        <li>
                            <img src={"/static/icons/primary-damage.svg"} className={"car-conditions-icon"}
                                alt={"conditions"} />
                            <div>
                                <p className={"car-detail-status-label"}>მთავარი დაზიანება</p>
                                {
                                    data !== null && <p style={{ fontWeight: "bold" }}>{details[0].attributes.primary_damage.replaceAll("_", " ")}</p>}
                            </div>
                        </li>
                        <li>
                            <img src={"/static/icons/secondary-damage.svg"} className={"car-conditions-icon"}
                                alt={"conditions"} />
                            <div>
                                <p className={"car-detail-status-label"}>მეორადი დაზიანება</p>
                                {
                                    data !== null && <p style={{ fontWeight: "bold" }}>{details[0].attributes.secondary_damage.replaceAll("_", " ")}</p>}
                            </div>
                        </li>
                        <li style={{ width: "100%" }}>
                            <img src={"/static/icons/status-icon.svg"} className={"car-conditions-icon"}
                                alt={"conditions"} />
                            <div>
                                <p className={"car-detail-status-label"}>სტატუსი</p>
                                {
                                    data !== null && <p style={{ fontWeight: "bold" }}>{details[0].attributes.vehicle_state.replaceAll("_", " ")}</p>}
                            </div>
                        </li>
                    </ul>
                </div>}
                <div className={"bidding-section-container"}>
                    <h5 style={{ textAlign: "left" }}>ბიდის განთავსება</h5>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "20px"
                    }}><p className={"current-bid-amount"}>მიმდინარე ბიდი: </p><span style={{
                        color: "green",
                        fontWeight: "bold",
                        fontSize: "20px"
                    }}>${(isMarket && data !== null) ? data.price : (data !== null && data.data.attributes.highest_bid)}</span></div>
                    <Button variant={"contained"} onClick={() => {
                        if (user.isLoggedIn) {
                            setMaxBid({ show: true, amount: "" });
                        } else {
                            setNotification({ show: true, title: "საჭიროა ავტორიზაცია", body: "გთხოვთ გაიაროთ ავტორიზაცია." });
                        }
                    }} className={"place-your-highest-bid"}>დადეთ მაქს. ბიდი</Button>
                    {!isMarket && <h5 style={{ marginTop: "40px" }} >იყიდე ახლავე: <span
                        style={{ color: "green" }}>${data !== null && data.data.attributes.buy_now_price}</span></h5>}
                    <Button variant={"contained"} onClick={() => {
                        if(user.isLoggedIn){
                            setBuyNow(true);
                        }else{
                            setNotification({ show: true, title: "საჭიროა ავტორიზაცია", body: "გთხოვთ გაიაროთ ავტორიზაცია." });
                        }
                    }} style={{ width: "100%" }} color={"success"}>ყიდვა</Button>
                </div>
            </div>
            {!isMarket && <div className="main-page-cars-catalogue car-details-slider" style={{ marginBottom: "70px 0 50px 0" }}>
                <div className='section-header-with-button'>
                    <h4 className="section-title" style={{ paddingTop: "0px" }}>შეარჩიეთ ავტომობილი</h4>
                    <div>
                        <button className='slide-switcher-button' onClick={slideToPrevItem}>{"<"}</button>
                        <button className='slide-switcher-button' onClick={slideToNextItem}>{">"}</button>
                    </div>
                </div>
                <div>
                    {carouselFragment}
                </div>
            </div>}
            <Modal show={showConsultationModal} onHide={() => setShowConsultationModal(false)} centered size={"md"}>
                <Modal.Header><h4>კონსულტაციის მოთხოვნა</h4></Modal.Header>
                <Modal.Body>
                    <div>
                        <div className={"consultation-modal-details"}>
                            <img src={images[0]} className={"ask-for-consultation-image"} alt={"vehicle"} />
                            {/* <div className={"car-details"}>
                                <h5>{data !== null && (isMarket ? data.data.attributes.name : `${data.year} ${data.mark} ${data.model}`)}</h5>
                                <h6>{`${data !== null && (!isMarket ? details[0].attributes.vin_code.slice(0, -6) : data.vin.slice(0, -6))}`}<span
                                    style={{ color: "red" }}>{data !== null && (!isMarket ? details[0].attributes.vin_code.slice(-6) : data.vin.slice(-6))}</span></h6>
                                {!isMarket && <h6>{data !== null && details[0].attributes.primary_damage.replaceAll("_", " ")}</h6>}
                            </div> */}
                        </div>
                        <form className={"car-consultation-form"} onSubmit={handlers.consultation}>
                            <div className={"form-group"}>
                                <label>სახელი</label>
                                <input required value={formData.name}
                                    onChange={(e) => setFormData((prev) => ({ ...prev, name: e.target.value }))}
                                    className={"form-control"} type={"text"} />
                            </div>
                            <div className={"form-group"}>
                                <label>ნომერი</label>
                                <input required value={formData.phone}
                                    onChange={(e) => (/^[0-9]+$/.test(e.target.value) || e.target.value === "") && setFormData((prev) => ({
                                        ...prev,
                                        phone: e.target.value
                                    }))} className={"form-control"} type={"text"} />
                            </div>
                            <Button variant={"contained"} type={"submit"} style={{ width: "100%", marginTop: "15px" }}
                                endIcon={<SendIcon />}>მოთხოვნის გაგზავნა</Button>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={notification.show} onHide={() => setNotification((prev) => ({ ...prev, show: false }))} size="md">
                <Modal.Header><h4>{notification.title}</h4></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
            </Modal>
            <Modal show={maxBid.show} onHide={() => setMaxBid({ show: false, amount: "" })}>
                <Modal.Header><h4>მაქს. ბიდის დამატება</h4></Modal.Header>
                <Modal.Body>
                    <div className='form-group' >
                        <label>ბიდი</label>
                        <input type='number' value={maxBid.amount} className='form-control' onChange={(e) => setMaxBid((prev) => ({ ...prev, amount: e.target.value }))} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={handlers.maxBid} variant='contained'>გაგზავნა</Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={buyNow} onHide={() => setBuyNow(false)}>
                <Modal.Header><h4>ავტომობილის ყიდვის მოთხოვნის გაგზავნა</h4></Modal.Header>
                <Modal.Body>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={handlers.placeBuyNowRequest} style={{ marginRight: "20px" }} color='success' variant='contained'>დიახ</Button>
                        <Button onClick={() => setBuyNow(false)} variant='contained'>არა</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CarDetails