import PhoneIcon from '@mui/icons-material/PhoneAndroid';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import { Button, FormControl, Input, InputAdornment, InputLabel, TextField } from '@mui/material'
import SendIcon from '@mui/icons-material/Send';
import '../styles/contacts/Contacts.css'
import { useState } from 'react'
import axios from "axios";
import {Modal} from "react-bootstrap";

const Contact = () => {
    const [formData, setFormData] = useState({ name: "", phone: "", email: "", message: "" });
    const [notification, setNotification] = useState({show: false, title: "", body: ""});

    const submitContactForm = (e) => {
        e.preventDefault();
        axios.post('/api/contacts', formData)
            .then(() => {
                setNotification({show: true, title: "მოთხოვნა წარმატებით გაიგზავნა", body: "კონსულტაციის მოთხოვნა გაგზავნილია"});
                setFormData({ name: "", phone: "", email: "", message: "" });
            })
            .catch(() => {
                setNotification({show: true, title: "შეცდომა", body: "წაღაც გაუთვალისწინებელი მოხდა"})
                setFormData({ name: "", phone: "", email: "", message: "" });
            })
    }

    return (
        <div className="contact-page-container">
            <div className='contact-page-content'>
                <div className='contact-form-container'>
                    <h4>კონტაქტის ფორმა</h4>
                    <form className='contact-form-box' onSubmit={submitContactForm}>
                        <FormControl variant="standard" className="contact-page-fields">
                            <InputLabel htmlFor="input-with-icon-adornment">
                                ჩაწერეთ სახელი
                            </InputLabel>
                            <Input
                                required
                                type='text'
                                value={formData.name}
                                onChange={(e) => !(/^[0-9]+$/.test(e.target.value)) && setFormData((prev) => ({ ...prev, name: e.target.value }))}
                                name='name'
                                id="name"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl variant="standard" className="contact-page-fields">
                            <InputLabel htmlFor="input-with-icon-adornment">
                                ჩაწერეთ ტელ. ნომერი
                            </InputLabel>
                            <Input
                                required
                                type='text'
                                value={formData.phone}
                                onChange={(e) => (/^[0-9]+$/.test(e.target.value) || e.target.value === "") && setFormData((prev) => ({ ...prev, phone: e.target.value }))}
                                name='phone'
                                id="phone"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <PhoneIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl variant="standard" className="contact-page-fields">
                            <InputLabel htmlFor="input-with-icon-adornment">
                                ჩაწერეთ ელ. ფოსტა
                            </InputLabel>
                            <Input
                                required
                                type='email'
                                value={formData.email}
                                onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))}
                                name='email'
                                id="email"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <TextField
                            id="form-message"
                            label="შეტყობინება"
                            multiline
                            onChange={(e) => setFormData((prev) => ({ ...prev, message: e.target.value }))}
                            rows={4}
                            variant="standard"
                        />
                        <Button endIcon={<SendIcon/>} type={"submit"} style={{width: "100%", marginTop: "35px"}} variant="contained">გაგზავნა</Button>
                    </form>
                </div>
                <div className='contact-details-container'>
                    <h4>მდებარეობა</h4>
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d613.8042190751363!2d44.76255145666841!3d41.72904127915586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x404472e102e66fab%3A0xbf2bcc2b2ca4a90a!2sGreen%20Budapest!5e0!3m2!1ska!2sge!4v1715872929394!5m2!1ska!2sge" 
                        width="100%"
                        height="550"
                        title="our location map"
                        style={{ border: "0" }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <Modal show={notification.show} onHide={() => setNotification((prev) => ({...prev, show: false}))} size="md">
                <Modal.Header><h4>{notification.title}</h4></Modal.Header>
                <Modal.Body>{notification.body}</Modal.Body>
            </Modal>
        </div>
    )
}

export default Contact