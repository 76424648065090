import './Services.css'
import Zoom from 'react-reveal/Zoom';

const Services = () => {
    return (
        <section className="services-container">
            <h4 className="section-title">ჩვენი სერვისები</h4>
            <div className='services-sections-list'>
                <Zoom>
                    <div className='services-item'>
                        <div className='service-image-shadow' style={{background: "rgb(233, 239, 245)"}}><img src='/static/icons/services/consultation.svg' alt='service-images' className='services-icons' /></div>
                        <p className={"services-title"}>კონსულტაცია</p>
                        <p className='service-description-text'>ვარჩევთ შენს ავტომობილს და ვეძებთ საუკეთესო ვარიანტებს ამერიკის აუქციონებზე</p>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='services-item'>
                        <div className='service-image-shadow' style={{background: "rgb(255, 237, 240)"}}><img src='/static/icons/services/buyCar.svg' alt='service-images' className='services-icons'/></div>
                        <p className={"services-title"}>შეძენა</p>
                        <p className='service-description-text'>ვყიდულობთ შენს ავტომობილს ამერიკის უმსხვილეს აუქციონებზე, საბაზროზე 30%-ით იაფად</p>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='services-item'>
                        <div className='service-image-shadow' style={{background: "rgb(235, 243, 237)"}}><img src='/static/icons/services/funding.svg' alt='service-images' className='services-icons'/></div>
                        <p className={"services-title"}>დაფინანსება</p>
                        <p className='service-description-text'>სურვილის შემთხვევაში ვაფინანსებთ თქვენი ავტომობილისა და ტრანსპორტირების ხარჯებს</p>
                    </div>
                </Zoom>
                <Zoom>
                    <div className='services-item'>
                        <div className='service-image-shadow' style={{background: "rgb(250, 241, 232)"}}><img src='/static/icons/services/transportation.svg' alt='service-images' className='services-icons'/></div>
                        <p className={"services-title"}>ტრანსპორტირება</p>
                        <p className='service-description-text'>ვზრუნავთ თქვენი ავტომობილის სწრაფ და უსაფრთხო ტრანსპორტირებაზე</p>
                    </div>
                </Zoom>
            </div>
        </section>
    )
}

export default Services