const RegAsDealer = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={`${props.iconWidth}px`}
      fill={props.color ? props.color : "black"}
      viewBox="0 0 47.83 47.83"
    >
      <g
        id="vuesax_linear_profile"
        dataname="vuesax/linear/profile"
        transform="translate(-108 -252)"
      >
        <g id="profile" transform="translate(108 252)">
          <path
            id="Vector"
            d="M9.146,17.637a3.614,3.614,0,0,0-.656,0,8.838,8.838,0,1,1,.656,0Z"
            transform="translate(15.087 3.977)"
            fill="none"
            stroke="#292d32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Vector-2"
            dataname="Vector"
            d="M3.623,2.729c-4.831,3.221-4.831,8.47,0,11.672,5.49,3.659,14.494,3.659,19.984,0,4.831-3.221,4.831-8.47,0-11.672C18.137-.91,9.133-.91,3.623,2.729Z"
            transform="translate(10.628 26.331)"
            fill="none"
            stroke="#292d32"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H47.83V47.83H0Z"
            transform="translate(47.83 47.83) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};

export default RegAsDealer;
