import './DataTable.css';
import { Table } from "react-bootstrap";
import { useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OutsideClickHandler from 'react-outside-click-handler';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const DataTable = ({
    data,
    searchPlaceHolder,
    withoutSearchButton,
    searchHandler,
    title,
    actionButtons,
    headers,
    limitControl,
    setLimit,
    limit,
    showParams,
    pageChanged,
    setPageChanged,
    total,
    rowActions,
    rowActionsList,
    setActiveRow,
    withSelectFilter,
    selects,
    dateRange,
    setDate,
    selectValue,
    withImage
}) => {
    const [keyword, setKeyword] = useState("");
    const [rowActionsDropdown, setRowActionsDropdown] = useState({
        id: "",
        show: false
    })

    return (
        <>
            <h4 className={"section-title"}>{title}</h4>
            <div className={"data-table-header"}>
                <div className={"data-table-search-box"}>
                    <input
                        type={"search"}
                        className={"form-control"}
                        title={withoutSearchButton ? "Press 'Enter' to start searching" : ""}
                        placeholder={searchPlaceHolder ? searchPlaceHolder : "Enter Keyword"}
                        onChange={(e) => setKeyword(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) searchHandler(keyword);
                        }}
                    />
                    <button className={"blue-button"} onClick={() => searchHandler(keyword)}>Search</button>
                </div>
                {
                    withSelectFilter && (
                        <div className={"select-filters"}>
                            {
                                selects && selects.map((s) => {
                                    return (
                                        <select key={s.id} className={"form-control"} value={selectValue}
                                            onChange={(e) => s.action(e.target.value)}>
                                            <option>{s.label}</option>
                                            {
                                                s.options.map((o) => {
                                                    return (
                                                        <option key={o.id} value={o.value}>{o.label}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    )
                                })
                            }
                            {dateRange === true && <>
                                <div className={"date-selects"} style={{ marginLeft: "8px" }}>
                                    <input type={"date"} className={"form-control"} onChange={(e) => setDate((prev) => {
                                        const date = new Date(e.target.value);
                                        const year = date.getFullYear();
                                        const month = String(date.getMonth() + 1).padStart(2, '0');
                                        const day = String(date.getDate()).padStart(2, '0');
                                        return { ...prev, from: `${month}-${day}-${year}` }
                                    })} />
                                </div>
                                <div className={"date-selects"} style={{ marginLeft: "8px" }}>
                                    <input type={"date"} className={"form-control"} onChange={(e) => setDate((prev) => {
                                        const date = new Date(e.target.value);
                                        const year = date.getFullYear();
                                        const month = String(date.getMonth() + 1).padStart(2, '0');
                                        const day = String(date.getDate()).padStart(2, '0');
                                        return { ...prev, to: `${month}-${day}-${year}` }
                                    })} />
                                </div>
                                <Button onClick={() => setDate({ from: "", to: "" })}>Clear</Button>
                            </>}
                        </div>
                    )
                }
                {
                    actionButtons && (
                        <div className={"database-header-actions"}>
                            {limitControl && <select className={"form-control"} value={limit} title={"Items per page"}
                                style={{ width: "80px" }}
                                onChange={(e) => setLimit(e.target.value)}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                            </select>}
                            {actionButtons.map((buttons, k) => {
                                return (<button key={k * 15} className={"blue-button data-table-action-buttons"}
                                    style={{ background: buttons.background ? buttons.background : "#0a56f7" }}
                                    onClick={buttons.action}>
                                    {buttons.name}
                                </button>)
                            })}
                        </div>
                    )
                }
            </div>
            <div className={"data-table-container"}>
                <Table responsive={true}>
                    <thead>
                        <tr>
                            {
                                withImage && <th></th>
                            }
                            {headers.map((h, k) => {
                                return <th key={k * 110}>{h}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.length > 0 ? (
                                data.map((d) => {
                                    const isOrderPage = d.isPayed !== "undefined";
                                    let isPayed = false;
                                    let rowClass = ""
                                    if (isOrderPage) {
                                        isPayed = d.isPayed;
                                        const payment_date = +new Date(d.payment_date);
                                        if (isPayed) {
                                            rowClass += "payed-row"
                                        }
                                        if (payment_date - +new Date() < 259200000 && isPayed === false) {
                                            rowClass += "payment-needed-row";
                                        }

                                    }
                                    return (
                                        <tr key={d.id * 5}>
                                            {
                                                withImage && <td><Link to={`/lot/${d.lot}`}><img style={{height: "80px"}} src={d.images[0]} alt='car'/></Link></td>
                                            }
                                            {
                                                showParams.map((p, l) => {
                                                    return (
                                                        <td key={l * 1000} className={rowClass}>{typeof d[p] === "string" ? d[p].replaceAll("_", " ") : d[p]}</td>
                                                    )
                                                })
                                            }
                                            {
                                                rowActions && (
                                                    <td className={rowClass}>
                                                        <div className='table-actions-container'>
                                                            <MoreVertIcon className={"actions-dropdown"}
                                                                onClick={() => setRowActionsDropdown({
                                                                    id: d.id,
                                                                    show: true
                                                                })} />
                                                            {(rowActionsDropdown.show && rowActionsDropdown.id === d.id) &&
                                                                <div className={"actions-dropdown-box"}>
                                                                    <OutsideClickHandler style={{ width: "50px" }} onOutsideClick={() => {
                                                                        setRowActionsDropdown({ id: "0", show: false })
                                                                    }}>

                                                                        <ul className={"actions-list"}>
                                                                            {
                                                                                rowActionsList.map((a) => {
                                                                                    return (
                                                                                        <li onClick={() => {
                                                                                            setActiveRow({ ...d });
                                                                                            setRowActionsDropdown({
                                                                                                id: "0",
                                                                                                show: false
                                                                                            })
                                                                                            a.action({ ...d });
                                                                                        }} key={a.id}>{a.name}</li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </OutsideClickHandler> </div>}
                                                        </div>
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={headers.length}>Records Not Found</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
            <div className={"data-table-footer"}>
                <div className="pagination-container">
                    {pageChanged > 1 && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev - 1))
                    }}>
                        {'<<'}
                    </span>}
                    {
                        Array.from(Array(Math.ceil(total / 10)).keys())
                            .map((p, k) => {
                                if (p < pageChanged - 2 || p + 1 > pageChanged + 2) {
                                    return ""
                                } else {
                                    return (<span key={k * 15}
                                        className={(pageChanged === p + 1) ? "active-page-number" : undefined}
                                        onClick={() => {
                                            setPageChanged(p + 1)
                                        }
                                        }>
                                        {p + 1}
                                    </span>)
                                }
                            })
                    }
                    {pageChanged < Math.ceil(total / 10) && <span className="next-page-button" onClick={() => {
                        setPageChanged((prev) => (prev + 1))
                    }}>
                        {'>>'}
                    </span>}
                </div>
                <p className={"table-total-count"} style={{ marginBottom: "0px" }}><strong>Count:</strong> {total}</p>
            </div>
        </>
    )
}

export default DataTable