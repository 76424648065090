import { Link } from "react-router-dom";
import './Car.css';

const Car = ({ data, idSetter, isSlider }) => {
    return (
        <Link to={`/lot/${data.id}`} className={isSlider ? "slider-items-car-container" : ""} style={{width: "100%"}}>
            <div className='car-item-container' onClick={() => {
                if(typeof idSetter !== "undefined") idSetter();
            }}>
                <div className='car-item-image' style={{ backgroundImage: `url('${data.attributes.images[0]}')` }}></div>
                <div className='car-details-container'>
                    <h5 className='car-details-name'>{`${data.attributes.name}`}</h5>
                    <p className='car-details-primary-damage'><strong style={{ color: "black" }}>დაზიანება: </strong> {data.attributes.primary_damage.replaceAll("_", " ")}</p>
                    <hr className='hr' />
                    <div className='car-details-city-and-bid'>
                        <div>
                            <p className='car-details-location car-details-primary-damage'><strong style={{ color: 'black' }}>ქალაქი: </strong>{data.attributes.city}</p>
                            <p className='car-details-buy-now-amount'><strong style={{ color: 'black' }}>იყიდე ახლავე: </strong> ${data.attributes.buy_now_price}</p>
                        </div>
                        <div className='car-details-bid-section'>
                            <p className='car-details-high-bid'>{data.attributes.highest_bid}</p>
                            <p className="car-details-currency">USD</p>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default Car